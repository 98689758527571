<template>
  <div class="prove-enterprise">
    <div class="chat-head">
      <div class="head-left">
        <el-image
          class="head-icon"
          :src="require(`../../../assets/image/user.png`)"
          fit="contain"
        ></el-image>
        <div class="head-title">公司认证</div>
      </div>
      <el-image
        class="head-right"
        @click="sendExit"
        :src="require(`../../../assets/image/exit2.png`)"
        fit="contain"
      ></el-image>
    </div>
    <!-- 表单数据 -->
    <div class="myform" v-show="!is_enable && is_enable !== 0">
      <el-form
        :model="userForm"
        ref="rulForm"
        :rules="rules"
        label-width="100px"
      >
        <div class="form-top">
          <div
            v-if="is_attestation"
            style="margin-bottom: 10px; font-size: 18px; color: brown"
          >
            已认证
          </div>
          <div class="form-grade">
            <el-form-item
              label="统一社会信用代码"
              prop="grade"
              label-width="140px"
            >
              <el-input
                class="form-input"
                :disabled="is_attestation"
                size="small"
                v-model="userForm.grade"
                placeholder="请输入统一社会信用代码"
              >
                <!-- <el-button
                  slot="append"
                  @click="search"
                  icon="el-icon-search"
                ></el-button> -->
              </el-input>
            </el-form-item>
          </div>
          <div class="form-compo" v-if="is_show">
            <div class="form-compo-div">
              <el-form-item label="企业名称" prop="chain_name">
                <el-input
                  class="form-input"
                  :disabled="is_attestation"
                  size="small"
                  v-model="userForm.chain_name"
                  placeholder="请输入企业名称"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="企业负责人" prop="principal">
                <el-input
                  class="form-input"
                  :disabled="is_attestation"
                  size="small"
                  v-model="userForm.principal"
                  placeholder="请输入企业负责人"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="HR联系方式" prop="contact_way">
                <el-input
                  class="form-input"
                  :disabled="is_attestation"
                  size="small"
                  v-model="userForm.contact_way"
                  placeholder="请输入HR联系方式"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <!-- <el-cascader
              v-model="cityData"
              :options="cityOptions"
              @change="handleCityChange"
              class="formValue"
            ></el-cascader> -->
          <div class="form-compo" v-if="is_show">
            <div class="form-compo-div">
              <el-form-item label="公司省份" prop="address">
                <el-input
                  class="form-input"
                  :disabled="is_attestation"
                  size="small"
                  v-model="userForm.address"
                  placeholder="请输入省份"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="公司市区" prop="job_district">
                <el-input
                  class="form-input"
                  :disabled="is_attestation"
                  size="small"
                  v-model="userForm.job_district"
                  placeholder="请输入公司市区"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="详细地址" prop="job_street">
                <el-input
                  class="form-input"
                  :disabled="is_attestation"
                  size="small"
                  v-model="userForm.job_street"
                  placeholder="请输入公司详细地址"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="form-compo" v-if="is_show">
            <div class="form-compo-div">
              <el-form-item label="融资阶段" prop="job_financing">
                <el-input
                  class="form-input"
                  :disabled="is_attestation"
                  size="small"
                  v-model="userForm.job_financing"
                  placeholder="请输入融资阶段"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="公司规模" prop="job_scale">
                <el-input
                  class="form-input"
                  :disabled="is_attestation"
                  size="small"
                  v-model="userForm.job_scale"
                  placeholder="请输入公司规模"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="公司行业" prop="job_industry">
                <el-input
                  class="form-input"
                  :disabled="is_attestation"
                  size="small"
                  v-model="userForm.job_industry"
                  placeholder="请输入公司行业"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="form-compo" v-if="is_show">
            <div class="form-compo-div">
              <el-form-item label="上下班时间" prop="job_time">
                <el-input
                  class="form-input"
                  :disabled="is_attestation"
                  size="small"
                  v-model="userForm.job_time"
                  placeholder="请输入上下班时间"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <el-row v-if="is_show">
            <el-col :span="14"
              ><div class="formOddOrEvenDays">
                <el-form-item label="单双休" prop="job_is_days">
                  <el-input
                    class="form-input"
                    :disabled="is_attestation"
                    size="small"
                    v-model="userForm.job_is_days"
                    placeholder="请输入单双休"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="formTextArea">
                <el-form-item label="发展历程" prop="job_course">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 6 }"
                    class="form-input"
                    :disabled="is_attestation"
                    size="small"
                    v-model="userForm.job_course"
                    placeholder="请输入发展历程"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="formTextArea">
                <el-form-item label="企业介绍" prop="introduce">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 6 }"
                    class="form-input"
                    :disabled="is_attestation"
                    size="small"
                    v-model="userForm.introduce"
                    placeholder="请输入企业介绍"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="8"
              ><div class="form-pic-desc">
                <el-form-item label="公司LOGO" prop="pic">
                  <el-image
                    v-if="showLogoImg"
                    class="pic-img"
                    :src="userForm.pic"
                  ></el-image>
                </el-form-item>
                <div class="avatar-uploader" v-if="!is_attestation">
                  <i
                    class="el-icon-plus avatar-uploader-icon"
                    @click="addPic('logo')"
                  ></i>
                </div>
              </div>
              <div class="form-pic-desc">
                <el-form-item label="营业执照" prop="business_license">
                  <el-image
                    v-if="showCollegeImg"
                    class="pic-img"
                    :src="userForm.business_license"
                  ></el-image>
                </el-form-item>
                <div class="avatar-uploader" v-if="!is_attestation">
                  <i
                    class="el-icon-plus avatar-uploader-icon"
                    @click="addPic('营业执照')"
                  ></i>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm"
              >认证</el-button
            >
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="audit" v-if="is_enable === 0 || is_enable">
      <div class="auditMain">
        <div
          :class="`${
            is_enable === 2 ? 'certificationImg70' : 'certificationImg'
          }`"
        >
          <el-image
            class="waitingCertification"
            :src="require('@/assets/image/waitingCertification.png')"
            v-if="is_enable === 0"
          ></el-image>
          <el-image
            class="successIdentity"
            :src="require('@/assets/image/successIdentity.png')"
            v-if="is_enable === 1"
          ></el-image>
          <el-image
            class="authentiCationfailure"
            :src="require('@/assets/image/authentiCationfailure.png')"
            v-if="is_enable === 2"
          ></el-image>
        </div>
        <div class="reason" v-if="is_enable === 2">
          理由：{{ is_enable_content }}
        </div>
      </div>
      <div class="auditButton">
        <el-button
          v-if="is_enable === 0 || is_enable === 1"
          class="button"
          type="info"
          size="mini"
          :disabled="is_enable === 0 || is_enable === 1"
          >认证</el-button
        >
        <el-button
          v-if="is_enable === 2"
          class="button"
          type="warning"
          size="mini"
          @click="onAgainCationfailure"
          >重新认证</el-button
        >
        <el-button size="mini" @click="resetForm">返回</el-button>
      </div>
    </div>
    <!-- 弹窗 -->
    <MyDialog
      :dialogVisible="dialogVisible"
      @changeDialog="changeDialog"
      @getPic="getPic"
      :limit="limit"
    />
  </div>
</template>

<script>
import {
  getAiUserDetails,
  insertEnterpriseDetails,
  getEnterpriseDetails,
  delChainMid,
} from "@/request/api";
import MyDialog from "@/components/MyDialog.vue";

export default {
  inject: ["reload"],
  components: {
    MyDialog,
  },
  data() {
    return {
      userForm: {
        chain_name: "", // 企业名称
        grade: "", // 统一社会信用代码
        principal: "", // 企业负责人
        contact_way: "", // HR联系方式
        introduce: "", // 企业介绍
        business_license: "", // 营业执照
        job_financing: "", // 融资阶段
        job_scale: "", // 公司规模
        job_industry: "", // 公司行业
        job_time: "", //上下班时间
        job_is_days: "", // 单双休
        job_course: "", // 发展历程
        address: "", // 省份
        job_district: "", // 市
        job_street: "", // 详细地址
        pic: "", // logo
        isNew: true,
      },
      rules: {
        chain_name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        principal: [
          { required: true, message: "请输入企业负责人", trigger: "blur" },
        ],
        contact_way: [
          { required: true, message: "请输入HR联系方式", trigger: "blur" },
          // {
          //   pattern: /^1[3-9]\d{9}$/,
          //   message: "请输入有效的手机号码",
          //   trigger: "blur",
          // },
        ],
        grade: [
          {
            min: 18,
            max: 18,
            required: true,
            message: "请输入正确的统一社会信用代码",
            trigger: "change",
          },
        ],
        address: [
          { required: true, message: "请输入公司省份", trigger: "blur" },
        ],
        job_district: [
          { required: true, message: "请输入公司市区", trigger: "blur" },
        ],
        job_street: [
          { required: true, message: "请输入公司详细地址", trigger: "blur" },
        ],
        job_financing: [
          { required: true, message: "请输入融资阶段", trigger: "blur" },
        ],
        job_scale: [
          { required: true, message: "请输入公司规模", trigger: "blur" },
        ],
        job_industry: [
          { required: true, message: "请输入公司行业", trigger: "blur" },
        ],
        job_time: [
          { required: true, message: "请输入上下班时间", trigger: "blur" },
        ],
        job_is_days: [
          { required: true, message: "请输入单双休", trigger: "blur" },
        ],
        job_course: [
          { required: true, message: "请输入发展历程", trigger: "blur" },
        ],
        introduce: [
          { required: true, message: "请输入企业介绍", trigger: "blur" },
        ],
        business_license: [
          { required: true, message: "未上传", trigger: "blur" },
        ],
        pic: [{ required: true, message: "未上传", trigger: "blur" }],
      },
      is_attestation: false,
      is_show: true,
      showCollegeImg: false, // 显示营业执照
      showLogoImg: false, // 显示logo
      // 弹窗
      dialogVisible: false,
      // 限制图片数量
      limit: 0,
      picType: "", // logo还是营业执照
      is_enable: null, // 审核状态
      is_enable_content: "", // 审核未通过原因
    };
  },
  watch: {
    // "userForm.grade"() {
    //   if (this.userForm.grade.length === 18) {
    //     this.search();
    //   }
    // },
  },
  methods: {
    // onChangeEducation() {
    //   console.log(
    //     this.userForm.grade.length,
    //     "this.userForm.grade.length"
    //   );
    //   if (this.userForm.grade.length === 18) {
    //     this.search();
    //   }
    // },
    async onAgainCationfailure() {
      const res = await delChainMid();
      if (res.data.code === "1000") {
        this.$message.success("已重新认证");
        this.init();
      }
    },
    sendExit() {
      this.$emit("update-value");
    },
    async search() {
      if (this.is_attestation) return this.$message.warning("已经认证过了");

      try {
        const res = await getEnterpriseDetails(this.userForm.grade);
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        const details = res.data.data;
        this.is_show = true;
        if (details) {
          this.userForm.chain_name = details.chain_name;
          this.userForm.principal = details.principal;
          this.userForm.contact_way = details.contact_way;
          this.userForm.address = details.address;
          this.userForm.job_street = details.job_street;
          this.userForm.job_district = details.job_district;
          this.userForm.job_financing = details.job_financing;
          this.userForm.job_scale = details.job_scale;
          this.userForm.job_industry = details.job_industry;
          this.userForm.job_time = details.job_time;
          this.userForm.job_is_days = details.job_is_days;
          this.userForm.job_course = details.job_course;
          this.userForm.introduce = details.introduce;
          this.userForm.business_license = details.business_license;
          this.userForm.pic = details.pic;
          this.showCollegeImg = true;
          this.showLogoImg = true;
        } else {
          this.userForm.chain_name = "";
          this.userForm.principal = "";
          this.userForm.contact_way = "";
          this.userForm.address = "";
          this.userForm.job_street = "";
          this.userForm.job_district = "";
          this.userForm.job_financing = "";
          this.userForm.job_scale = "";
          this.userForm.job_industry = "";
          this.userForm.job_time = "";
          this.userForm.job_is_days = "";
          this.userForm.job_course = "";
          this.userForm.introduce = "";
          this.userForm.business_license = "";
          this.userForm.pic = "";
        }
      } catch (error) {
        this.$message.error("请求失败", error);
      }
    },
    submitForm() {
      if (this.is_attestation) return this.$message.warning("已经认证过了");

      this.$refs.rulForm.validate((valid) => {
        if (valid) {
          this.$confirm("认证后不可更改，是否继续？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              try {
                const res = await insertEnterpriseDetails(this.userForm);
                if (res.status !== 200 || res.data.code !== "1000") {
                  return this.$message.error(res.data.msg);
                }
                this.reload();
                // this.init()
                this.$message.success("已提交认证申请");
              } catch (error) {
                this.$message.error("提交失败");
              }
            })
            .catch(() => {
              this.$message({ type: "info", message: "已取消认证" });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 上传营业执照 限制1张
    addPic(type) {
      this.picType = type;
      this.limit = 1;
      this.dialogVisible = true;
    },
    // 返回
    resetForm() {
      this.$router.back();
    },
    // 取消按钮 关闭弹窗
    changeDialog() {
      this.dialogVisible = false;
    },
    // 组件保存图片的回调
    getPic(picArr, id) {
      this.dialogVisible = false;
      picArr.forEach((pic) => {
        if (id === 1) {
          if (this.picType === "logo") {
            this.userForm.pic = pic;
            this.showLogoImg = true;
          } else {
            this.userForm.business_license = pic;
            this.showCollegeImg = true;
          }
        }
      });
    },
    // 初始化操作
    async init() {
      const res = await getAiUserDetails();
      if (res.status !== 200 || res.data.code !== "1000") {
        return this.$message.error(res.data.msg);
      }
      // if (
      //   res.data.data.getAiUser.is_enable ||
      //   res.data.data.getAiUser.is_enable === 0
      // ) {
      //   this.is_enable = res.data.data.getAiUser.is_enable;
      //   this.is_enable_content = res.data.data.getAiUser.is_enable_content;
      // }else{

      // }
      this.is_enable = res.data.data.getAiUser.is_enable;
      this.is_enable_content = res.data.data.getAiUser.is_enable_content;
      const details = res.data.data.getAiChain[0];
      if (details.chain_name !== "游客") {
        this.is_attestation = true;
        this.is_show = true;
      }

      this.userForm.chain_name = details.chain_name;

      this.userForm.introduce = details.introduce;
      this.userForm.address = details.address; // 省
      this.userForm.job_street = details.job_street;
      this.userForm.job_district = details.job_district;
      this.userForm.job_financing = details.job_financing;
      this.userForm.job_scale = details.job_scale;
      this.userForm.job_industry = details.job_industry;
      this.userForm.job_time = details.job_time;
      this.userForm.job_is_days = details.job_is_days;
      this.userForm.job_course = details.job_course;
      this.userForm.principal = details.principal;
      this.userForm.contact_way = details.contact_way;

      if (details.grade !== "没有") {
        this.userForm.grade = details.grade;
      }
      if (details.business_license !== null) {
        this.userForm.business_license = details.business_license;
        this.showCollegeImg = true;
      }
      if (details.pic !== null) {
        this.userForm.pic = details.pic;
        this.showLogoImg = true;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.prove-enterprise {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .audit {
    width: 100%;
    height: 100%;
    .auditMain {
      height: calc(100% - 200px);
      background: #fff;
    }
    .certificationImg {
      height: 100%;
      display: flex;
      align-items: center;
      .waitingCertification {
        width: 656px;
        height: 321px;
        margin: 0 auto;
      }
      .successIdentity {
        width: 443px;
        height: 400px;
        margin: 0 auto;
      }
    }
    .certificationImg70 {
      height: 70%;
      display: flex;
      align-items: center;
      .authentiCationfailure {
        width: 536px;
        height: 400px;
        margin: 0 auto;
      }
    }
    .reason {
      width: 800px;
      height: 58px;
      line-height: 58px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #4d94ff;
      margin: 0 auto;
      padding: 0 10px;
      color: #4d94ff;
    }
    .auditButton {
      width: 100%;
      background: #fff;
      margin-top: 20px;
      padding: 15px 0;
      .button {
        margin-left: 20px;
      }
    }
  }
  .myform {
    .formOddOrEvenDays {
      width: 50%;
    }
    .formTextArea {
      width: 100%;
    }
    .form-pic-desc {
      display: flex;
      align-items: center;
      margin-top: 15px;
      .avatar-uploader {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 120px;
          height: 120px;
          line-height: 120px;
          text-align: center;
        }
      }
      .avatar-uploader:hover {
        border-color: #409eff;
      }
      .pic-img {
        width: 120px;
        height: 120px;
        margin-right: 10px;
      }
    }
    .form-top {
      background: #fff;
      padding: 22px;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      .form-grade {
        .form-input {
          width: 30%;
        }
      }
      .form-compo {
        display: flex;
        .form-compo-div {
          width: 33%;
          // .form-input {
          //   width: 66%;
          // }
        }
      }
    }
    .form-button {
      background: #fff;
      padding: 20px 0px 2px 20px;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 10px 0px;
    }
  }
  .chat-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>
